import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  Box,
  Avatar,
  IconButton,
  Switch,
  Divider,
  Backdrop,
  CircularProgress,
  Typography,
  Tooltip,
  Modal
} from '@mui/material'
import LinkMaterial  from '@mui/material/Link';
import serviceCycles from '@services/serviceCycles'
import serviceCycleJourneys from '@services/serviceCycleJourneys'
import serviceParticipants from '@services/serviceParticipants'
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants'
import { useParams } from 'react-router-dom'
import {
  ArrowBack,
  Camera,
  CameraAlt,
  Check,
  Dangerous,
  Info,
  ReadMore
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useStyles } from '@styles/participants.style';
import Loading from '@components/Loading'
import 'moment/locale/es'
import { useTranslation } from 'react-i18next'
import getCurrentJourney from '@helpers/currentJourney'
import Button from '@mui/material/Button'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import EvButton from '@components/EvButton'
import Dialog from '@components/Dialog'
import Fallback from '@assets/images/avatar.png'
import CloseIcon from '@mui/icons-material/Close'

const Journey = () => {
  const classes = useStyles();
  const { t } = useTranslation()
  const { idJourney } = useParams()
  const [loading, setLoading] = useState(false)
  const [cycle, setCycle] = useState(null)
  const [journey, setJourney] = useState(null)
  const [participants, setParticipants] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openRemarks, setOpenRemarks] = useState(false)
  const [journeyRemarks, setJourneyRemarks] = useState('')
  const [idParticipant, setIdParticipant] = useState(null)
  const [idCycleJourneysParticipants, setIdCycleJourneysParticipants] =
    useState(null)
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [threeDaysBeforeEnd, setThreeDaysBeforeEnd] = useState(Math.random())
  const [imgPropertyMissing, setImgPropertyMissing] = useState(false)
  const [phonePropertyMissing, setPhonePropertyMissing] = useState(false)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    bgcolor: '#FFFF99',  // Fondo amarillo
    //border: '1px solid #000',
    boxShadow: 10,
    p: 3
  };

  useEffect(() => {
    getJourneyDetails()
  }, [])

  useEffect(() => {
    journeyRemarksCreate()
  }, [journey])

  useEffect(() => {
    let tmpImgPropertyMissing = false;
    let tmpPhonePropertyMissing = false;

    participants.forEach((participant) => {
      if (!participant.participantImage) {
        tmpImgPropertyMissing = true;
      }
      if (!participant.participantPhone) {
        tmpPhonePropertyMissing = true;
      }
    });

    setImgPropertyMissing(tmpImgPropertyMissing);
    setPhonePropertyMissing(tmpPhonePropertyMissing);
  }, [participants])

  useEffect(() => {
   if (imgPropertyMissing || phonePropertyMissing) {
    participants.forEach((participant) => {
      getParticipantDetails(participant.idParticipant)
    });
   }
  }, [imgPropertyMissing, phonePropertyMissing])
  
  //Llamada para obtyener el Código del Tipo de Ciclo de la JornadaSER
  const getCycleTypeCode = async () => {
    const { data, error } = await serviceCycleJourneys.getCycleJourneyByConsultant(0)
    if (!error && data.length > 0) {
      const matchingJourney = data.find(journey => journey.idCycleJourney === parseInt(idJourney)) // Asegura que idJourney sea un número
      if (matchingJourney) {
        return matchingJourney.cycleTypeCode
      }
    }
    return null
  }

  const getJourneyDetails = async() => {
    const { data, error } = await serviceCycleJourneys.getCycleJourneyDetails(idJourney)
    if (!error) {
      console.log(data);
      const cycleTypeCode = await getCycleTypeCode(); // Llama a getCycleTypeCode
      // Combina los datos de journey con cycleTypeCode
      setJourney({ ...data, cycleTypeCode })
      setParticipants(data.cycleJourneysParticipants)
      setLoading(false)
      const newDate = new Date(data.cycleJourneyDateTo);
      newDate.setDate(newDate.getDate() - 3);

      setThreeDaysBeforeEnd(newDate.toISOString())
    }
  }

  const getParticipantDetails = async(id) => {
    const { data, error } = await serviceParticipants.getParticipantsDetails(id)
    if (!error) {
      const updatedParticipantsArray = participants.map(participant => {
        if (participant.idParticipant === id) {
          return {
            ...participant,
            participantImage: data.participantImage,
            participantPhone: data.participantPhone
          };
        } else {
          return participant;
        }
      });
      
      setParticipants(updatedParticipantsArray);
    }
  }

  const updateJourney = async(dataJourney) => {
    const { data, error } = await serviceCycleJourneys.updateCycleJourney(dataJourney);
  }

  const updateRemarksSeen = async(IdCycleJourney, mark) => {
    const { data, error } = await serviceCycleJourneys.updateRemarksSeen(IdCycleJourney, mark);
  }

  const journeyRemarksCreate = () => {
    let remarkLanguage = t('CYCLES.JOURNEYS.NO_JOURNEY_REMARKS')
    const tempRemarks = journey?.cycleJourneyRemarks
      ? journey.cycleJourneyRemarks
      : remarkLanguage
    setJourneyRemarks(tempRemarks)

    if (journey && !journey?.remarksSeen && tempRemarks !== remarkLanguage) {
      setOpenRemarks(true)
    }
  }

  const handleAttendance = (
    idParticipant,
    idCycleJourneysParticipants,
    attendance
  ) => {
    if (attendance) {
      setIdParticipant(idParticipant)
      setIdCycleJourneysParticipants(idCycleJourneysParticipants)
      setOpenDialog(true)
    } else {
      setLoading(true)
      serviceCycleJourneysParticipants
        .deleteCycleJourneyParticipant(idCycleJourneysParticipants)
        .then(response => {
          if (!response.error) {
            getJourneyDetails()
          }
          setLoading(false)
        })
    }
  }
  const handleAttendanceDelete = (
    idParticipant,
    idCycleJourneysParticipants
  ) => {
    setLoading(true)
    var data = {
      idCycleJourney: journey.idCycleJourney,
      idParticipant: idParticipant,
      idCycleJourneysParticipants: idCycleJourneysParticipants,
      CycleJourneyAssistance: true
    }
    serviceCycleJourneysParticipants
      .updateCycleJourneParticipant(data)
      .then(response => {
        if (!response.error) {
          getJourneyDetails()
        }
        setOpenDialog(false)
        setLoading(false)
      })
  }

  const handleActivate = () => {
    journey.cycleTarget = true
    serviceCycles.updateCycleJourneys(journey).then(response => {
      if (!response.error) {
        getJourneyDetails()
      } else {
        journey.cycleTarget = false
      }
    })
    setReloadKey(Math.random())
  }

  let nombreSER = journey && journey.cycleTypeCode 
  ? `${journey.cycleTypeCode} - ${journey.cycleJourneyIdRecovery}D`
  : '';

  return (
    <div style={{ width: '100%', overflowX: 'hidden' }}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        height={200}
        title={t('CONFIRM.DELETE_INCIDENT')}
        subtitle={t('CONFIRM.DELETE_INCIDENT_SUBTITLE')}
        close={() => setOpenDialog(false)}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button
              variant='contained'
              onClick={() =>
                handleAttendanceDelete(
                  idParticipant,
                  idCycleJourneysParticipants
                )
              }
            >
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      {/*Cambia a Modal el Post-It para poder aplicarle estilos más fácilmente*/}
      <Modal
        open={openRemarks}
        onClose={() => setOpenRemarks(false)}
        aria-labelledby="post-it-title"
        aria-describedby="post-it-description"
      >
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="post-it-title" variant="h4" component="h3">
              {t('LABEL.POSTIT')}
            </Typography>
            {/* <IconButton
              onClick={() => setOpenRemarks(false)}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
              }}
            >
              <CloseIcon />
            </IconButton> */}
          </div>
          <Typography id="post-it-description" variant="h5" style={{ marginTop: '16px', color: '#0085b4' }}>
            <span dangerouslySetInnerHTML={{ __html: journeyRemarks }} />
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
            <Button
             variant='contained'
             onClick={() => {
              updateRemarksSeen(journey.idCycleJourney, false)
              setOpenRemarks(false)
             }}
             color='success'
             sx={{ mr: 1 }}
            >
              {t('BUTTON.VIEW_AGAIN')}
            </Button>
            <Button
            variant='contained'
            onClick={() => {
              updateRemarksSeen(journey.idCycleJourney, true)
              setOpenRemarks(false)
            }}
            color='error'
            >
              {t('BUTTON.CLOSE')}
            </Button>
          </div>
        </Box>
      </Modal>

      <Grid container spacing={1} padding={1} mt={0} ml={0} mr={0}>
        {journey ? (
          <>
            <Grid item xs={9} sx={{ color: '#FF9933' }}>
                <h2>{nombreSER}</h2>
            </Grid>
              <Grid item xs={6} display="flex" justifyContent="center" sx={{alignContent: "center", padding: '0px !important', borderRight: '1px dashed lightgrey'}}>
                <Tooltip title={t('LABEL.POSTIT')}>
                  <Button
                    onClick={() => {
                      setOpenRemarks(true);
                    }}>
                    <StickyNote2Icon  sx={{ color: '#FFC400', fontSize: '2.5em'}}/>
                  </Button>
                </Tooltip>
              </Grid>
              {/*
              // En JourneySer no había botón para mostrar las incidencias, si lo quieren habría que cambiar otras cosas.
              <Grid item xs={6} display="flex" justifyContent="center" sx={{alignContent: "center", padding: '0px !important'}}>
                <Tooltip title={t('TEXT.INCIDENT_CYCLE')}>
                  <Link to={"/cycleincidents/" + idJourney + "/" + journey.idCycleJourney}>
                    <ReportProblemRoundedIcon sx={{color: 'red', fontSize: '2em'}} />
                  </Link>
                </Tooltip>
              </Grid> */}
          

            {participants.map((participant, index) => {
              const participantImage  = participant?.participantImage ? participant.participantImage : Fallback      
              return (
                <Card key={participant.idParticipant} variant="outlined" style={{ marginBottom: '0px', width: '100%' }}>
                  <Grid container spacing={1} padding={2} display={'flex'} alignItems={'center'}>
                      <Grid item xs={2} display="flex" justifyContent="center" alignSelf={'start'}>
                          <Avatar
                              sx={{ width: '55px', height: '55px', marginTop: '10px'}}
                              src={`data:image/png;base64,${participantImage}`}
                          />
                      </Grid>
                      <Grid item xs={10} paddingY={'0 !important'}>
                          <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px'}} paddingX={1}>
                              <Typography noWrap sx={{color:'black', fontSize:'1.2em', textOverflow:'ellipsis', overflow: 'hidden'}}><Box component='span' sx={{color:'#0085B4', fontWeight:'bold'}}>{participant.participantNickName} </Box>- {participant.participantName} {participant.participantSurname}</Typography>
                              <Typography noWrap sx={{color:'black', fontSize:'1.2em', textOverflow:'ellipsis', overflow: 'hidden'}}>{participant.participantPosition}</Typography>
                              <Typography noWrap sx={{color:'black', fontSize:'1.2em', textOverflow:'ellipsis', overflow: 'hidden'}}>{participant.companyName}</Typography>
                          </Box>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center', marginTop: '-0.5em' }}>
                        <Typography variant='caption' sx={{ fontSize: '0.9em' }}>
                          {t('TEXT.ATTENDANCE')}
                        </Typography>
                        {participant.cycleJourneyAssistance == true ? (
                          <Link
                            to={''}
                            onClick={() =>
                              handleAttendance(
                                participant.idParticipant,
                                participant.idCycleJourneysParticipants,
                                false
                              )
                            }
                          >
                            <CheckBoxIcon sx={{color: 'orange', verticalAlign: 'middle', fontSize: '3em'}}/>
                          </Link>
                        ) : (
                          <Link
                            to={''}
                            onClick={() =>
                              handleAttendance(
                                participant.idParticipant,
                                participant.idCycleJourneysParticipants,
                                true
                              )
                            }
                          >
                            <DisabledByDefaultRoundedIcon sx={{color: 'red', verticalAlign: 'middle', fontSize: '3em'}}/>
                          </Link>
                        )}
                      </Grid>
                      <Grid item xs={10} paddingY={'0 !important'}>
                          <Box style={{ backgroundColor: 'white', color: '#0085B4', borderRadius: '5px', marginTop: '8px'}} paddingX={1}>
                              <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer', marginBottom: '10px' }} href={'mailto:'+participant.participantEmail}  target="_blank" >
                                  <Typography noWrap sx={{color:'#0085B4', fontSize:'1.2em', textOverflow:'ellipsis', overflow: 'hidden'}}>{participant.participantEmail}</Typography>
                              </LinkMaterial>
                              <LinkMaterial className={classes.colorSecondary} style={{ cursor: 'pointer' }} href={'tel:'+participant.participantPhone}  target="_blank" >
                                  <Typography noWrap sx={{color:'#e9a459', fontSize:'1.2em', textOverflow:'ellipsis', overflow: 'hidden'}}>{participant.participantPhone}</Typography>
                              </LinkMaterial>
                          </Box>
                      </Grid>
                  </Grid>
              </Card>
              )
            })}
            <Grid
              item
              xs={12}
              display='flex'
              justifyContent='center'
              marginTop={3}
              marginBottom={3}
            >
              <Grid item xs={8}>
                <Button
                  sx={{ width: '100%', fontSize: '18px', fontWeight: 'bold' }}
                  disabled={journey.cycleTarget === true}
                  variant='contained'
                  onClick={handleActivate}
                >
                  {journey.cycleTarget === false
                    ? t('BUTTON.ACTIVATE_TARGETS')
                    : t('BUTTON.TARGETS_ACTIVATED')}
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <Loading height='50vh' />
        )}
      </Grid>
      
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

export default Journey
