import React, {useState, useEffect, useContext} from 'react'
import { AuthContext } from '@context/auth/AuthContext';
import Dialog from '@components/Dialog'
import { Grid, Typography, CircularProgress, TextField, Button, Box, Select, MenuItem, TextareaAutosize, FormControlLabel, RadioGroup, Radio, } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { addNewEvent, updateEvent, deleteEvent } from '../../../services/schedulerServicesHelper'
import moment from 'moment';
import { useStyles } from '@styles/scheduler.style'
import CheckBox from "@components/CheckBox";
import {transformText} from '@helpers/common'
import { fetchFeeSlots } from '../../../services/schedulerServicesHelper'

const DialogEvent = ({
  openEventDetails,
  setOpenEventDetails,
  openEventSaveRecurrent,
  setOpenEventSaveRecurrent,
  setOpenEventEditeVerification,
  openEventEditeVerification,
  openLoading,
  setOpenLoading,
  setOpenFreeSlots,
  openFreeSlots,
  eventSelected,
  setEventSelected,
  idUserSelected,
  idEventSelected,
  categoriesList,
  userList,
  page,
  setPage,
  setLoading,
  freeSlots,
  setFreeSlots
}) => {
  const { user } = useContext(AuthContext)
  const [isDelete, setIsDelete] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()
  const [idUsersList, setIdUsersList] = useState([idUserSelected])
  const [freeSlotsSearch, setFreeSlotsSearch] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const eventsPerPage = 4 // Número de eventos por página
  
  // Función para manejar el cambio de página
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
   
  // Cálculo de eventos para la página actual
  const indexOfLastEvent = (currentPage + 1) * eventsPerPage
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage
  const currentEvents = freeSlots.slice(indexOfFirstEvent, indexOfLastEvent)

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1)
  }

  const totalPages = Math.ceil(freeSlots.length / eventsPerPage)
  const previousPageAvailable = currentPage > 0
  const nextPageAvailable = currentPage < totalPages - 1  

  const isOwn = (user.idUser === idUserSelected) ? true : false
  
  const isConsultant = user.userIdsRoles.every(num => num !== 1 && num !== 2)
  const isDisabled = () => {    
    if (isConsultant) {
      if (isOwn) {
        return false
      } else {
        return true
      }
    } else if (!isConsultant) {
      return false
    }
  }
  const categoryListBlocked = [10,19,20,24]  
    
  const handleChange = (e) => {
    const checked = e.target.checked
    const name = e.target.name
    const value = e.target.value
    
    if (name === 'eventDateFrom') {
      let copyValue = value;
      if (eventSelected.eventDateFrom && eventSelected.eventDateFrom !== undefined && eventSelected.eventDateFrom !== null && eventSelected.eventDateFrom.length > 0) {
        const oldTime = eventSelected.eventDateFrom.split("T")[1];
        copyValue = value + "T" + oldTime;
        
        setEventSelected((prevEvent) => ({
          ...prevEvent,
          [name]: copyValue,
          start: copyValue,
        }));
      }
    } else if (name === 'eventDateTo') {
      let copyValue = value;
      if (eventSelected.eventDateTo && eventSelected.eventDateTo !== undefined && eventSelected.eventDateTo !== null && eventSelected.eventDateTo.length > 0) {
        const oldTime = eventSelected.eventDateTo.split("T")[1];
        copyValue = value + "T" + oldTime;
        
        setEventSelected((prevEvent) => ({
          ...prevEvent,
          [name]: copyValue,
          end: copyValue,
        }));
      }
    } else if (name === 'eventTimeFrom') {
      let copyValue = value;
      if (eventSelected.eventDateFrom && eventSelected.eventDateFrom !== undefined && eventSelected.eventDateFrom !== null && eventSelected.eventDateFrom.length > 0) {
        const oldDate = eventSelected.eventDateFrom.split("T")[0];
        copyValue = oldDate + "T" + value;
        
        setEventSelected((prevEvent) => ({
          ...prevEvent,
          eventDateFrom: copyValue,
          start: copyValue,
        }));
      }
    } else if (name === 'eventTimeTo') {
      let copyValue = value;
      if (eventSelected.eventDateTo && eventSelected.eventDateTo !== undefined && eventSelected.eventDateTo !== null && eventSelected.eventDateTo.length > 0) {
        const oldDate = eventSelected.eventDateTo.split("T")[0];
        copyValue = oldDate + "T" + value;
        
        setEventSelected((prevEvent) => ({
          ...prevEvent,
          eventDateTo: copyValue,
          end: copyValue,
        }));
      }
    } else if (name === 'idCategory') {
      const matchingCategory = categoriesList.find(
        (category) => category.idCategory === value
      );
  
      const originalDate = new Date(eventSelected.eventDateFrom);
      const timezoneOffset = originalDate.getTimezoneOffset();
      const updatedDate = originalDate.getTime() + (matchingCategory.duration * 60000) 
      const updatedTimestamp = new Date(updatedDate - (timezoneOffset * 60000)); 
            
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        [name]: value,
        // Al elegir después una Categoría no se cambia la eventDateTo
        eventDateTo: prevEvent.eventDateTo && prevEvent.eventDateTo !== updatedTimestamp 
                      ? prevEvent.eventDateTo 
                      : updatedTimestamp,
        end: prevEvent.eventDateTo && prevEvent.eventDateTo !== updatedTimestamp 
                      ? prevEvent.eventDateTo 
                      : updatedTimestamp,
        
          // A los eventos se les asignan los bloqueos que tenga la categoría.
        eventTimePreBlocked: (matchingCategory.blockPre !== undefined && matchingCategory.blockPre !== null) ? matchingCategory.blockPre : (prevEvent.eventTimePreBlocked !== undefined && prevEvent.eventTimePreBlocked !== null) ? prevEvent.eventTimePreBlocked : 0,
        eventTimePostBlocked: (matchingCategory.blockPost !== undefined && matchingCategory.blockPost !== null) ? matchingCategory.blockPost : (prevEvent.eventTimePostBlocked !== undefined && prevEvent.eventTimePostBlocked !== null) ? prevEvent.eventTimePostBlocked : 0,
        eventColor: matchingCategory.color,
        eventFontColor: matchingCategory.fontColor,
        eventPrivate: (value === 12 || value === 22) ? true: false
      }));
    } else if (name === 'recurrentFrequencyUnit') {
      // Debe tener al menos una de las propiedades RecurrentDateLimit o RecurrentRepeats, ahora seteamos repeats
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        [name]: value,
        isRecurrent: value !== '' ? true : false,
        recurrentRepeats: value !== '' ? 10 : null, 
        recurrentFrequency: value !== '' ? 1 : null,
        // idRecurrentEvent: 
      }));
    } else if (name === 'allDay') {
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        [name]: checked,
      }));
    } else if (name === 'idUsers') {
      setIdUsersList(value);
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        [name]: value,
      }));
    } else if (name === 'eventInfo') {
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        eventInfo: value.replace(/\n/g, "<br />")  // Reemplaza los saltos de línea por <br />
      }));
    } else {
      setEventSelected((prevEvent) => ({
        ...prevEvent,
        [name]: value,
      }));
    }
  }

  const handleFreeSlotsRadio = (e) => { 
    setEventSelected((prevEvent) => ({
      ...prevEvent,
      eventDateFrom: eventSelected.freeSlotsRadio,
      eventDateTo: eventSelected.freeSlotsRadio
    }));

    setPage(1)
    setOpenFreeSlots(false)
  }

  const handleSaveButton = () => {
    setIsDelete(false)
    const matchingCategory = categoriesList.find(
      (category) => category.idCategory === eventSelected.idCategory
    );
    // Si tiene id es que ya existía y se llama el Update si no, se agregan todas las propiedades faltantes y se llama el New
    if (eventSelected.idSchedulerEvent) {
      if (eventSelected.isRecurrent || eventSelected.idRecurrentEvent) {
        setOpenEventSaveRecurrent(true)
      } else {
        updateEvent(eventSelected, setOpenLoading, setOpenEventDetails, setEventSelected)
      }
    } else {
      const newDataEvent = {
        ...eventSelected,
        idUsers: eventSelected.idUsers || idUsersList, 
        allDay: eventSelected.allDay || false,
        /*eventColor: eventSelected.color || matchingCategory.color,
        eventFontColor: '#000000',*/
        eventColor:  matchingCategory.color,
        eventFontColor: matchingCategory.fontColor,
        eventPrivate: eventSelected.eventPrivate || false,
        eventConfirmed: eventSelected.eventConfirmed || true,
        recurrentFrequencyUnit: eventSelected.recurrentFrequencyUnit || null,
        eventTimeNotice: eventSelected.eventTimeNotice || 0,
        eventTimezone: eventSelected.eventTimezone || 1,
        isRecurrent: eventSelected?.recurrentFrequencyUnit?.length > 0 ? true : false,
        createdUser: user.idUser
      }
      
      setEventSelected(newDataEvent)
 
      addNewEvent(newDataEvent, setOpenLoading, setOpenEventDetails, setEventSelected)
    }
  }

  const handleCloneButton = () => {
    setIsDelete(false)
    delete eventSelected.idSchedulerEvent
    setOpenEventDetails(false)
    sessionStorage.setItem('eventToClone', JSON.stringify(eventSelected))
  }

  const handleDeleteButton = () => {
    setIsDelete(true)
    if (eventSelected?.isRecurrent || eventSelected?.idRecurrentEvent) {
      setOpenEventSaveRecurrent(true)
    } else {
      deleteEvent(eventSelected, setOpenLoading, setEventSelected, setOpenEventDetails)
    }
  }

  // Primero salen las categorías preferidas según el orden que quieren, después el resto por orden alfabético
  const preferredCategories = [5, 13, 23, 7, 12]; // IDs de las categorías que se muestran primero
  const orderedCategories = [
    ...categoriesList
      .filter(category => preferredCategories.includes(category.idCategory))
      .sort((a, b) => preferredCategories.indexOf(a.idCategory) - preferredCategories.indexOf(b.idCategory)),
    ...categoriesList.filter(category => !preferredCategories.includes(category.idCategory))
  ];
    
  return (
    <>
      <Dialog
        title={t("TEXT.LOADING")}
        open={openLoading}
        hasCloseBtn={false}
        content={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
          <Box>  
                <CircularProgress />   
          </Box>
          <br />
          <Box>
            {t("EVALUATOR.LOADING_DATA")}
          </Box>
          </div>
        }
      />
      <Dialog
        open={openEventEditeVerification}
        close={() => {
          setOpenEventEditeVerification(false)
        }}
        subtitle={t('SCHEDULER.EDITED_VER')}
        width={250}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => {
                  setOpenEventEditeVerification(false)
                  // setEventSelected(null)
                }}
              >
                {t('BUTTON.CANCEL')}
              </Button>
              <Button
                sx={{marginLeft: '5px'}}
                variant='contained'
                color='success'
                onClick={() => {
                  updateEvent(eventSelected, setOpenLoading, setOpenEventDetails, setEventSelected)
                  setOpenEventEditeVerification(false)
                }}
              >
                {t('BUTTON.OK')}
              </Button>
            </Box>
          </Box>
        }
      />
      <Dialog
        topContent={true}
        open={openEventDetails}
        hasCloseBtn = {false}
              subtitle={`${t('SCHEDULER.EV_DETAILS')} (${moment(eventSelected?.eventDateFrom).format('DD/MM/YYYY')} ${moment(eventSelected?.eventDateFrom).format('dddd').toUpperCase()})`} //${eventSelected?.eventInfo ? `<br/> ${eventSelected.eventInfo}` : ''}
        width={600}
        // style={{
        //   alignItems: 'flex-start !important',
        // }}
        content={
          <Grid container spacing={0.5}>
            {page === 1 && (
              <>
                <Grid item xs={12}>
                  <Typography variant='caption' component='div'>
                    {t('LABEL.CATEGORY')}
                  </Typography>
                  <Select
                    placeholder={t('LABEL.CATEGORY') }
                    disabled={isDisabled()}
                    required
                    id='idCategory'
                    size='small'
                    name='idCategory'
                    fullWidth
                    value={eventSelected?.idCategory || ''}
                    onChange={(e) => handleChange(e)}
                  >
                    {orderedCategories?.map((category, i) => {
                      const isLastPreferred = i === preferredCategories.length - 1; // Si es el último de las categorías preferidas
                      return (
                        <MenuItem
                          key={category.idCategory}
                          value={category.idCategory}
                          sx={{
                            fontSize: '1.2rem' ,
                            padding: '2px 20px',
                            borderBottom: isLastPreferred
                              ? '2px solid black' // Línea negra después de las categorías preferidas
                              : '1px solid #d3d3d3', // Línea clara entre todos los elementos
                          }}
                        >
                          {category.categoryName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                {/* <Grid item xs={12} sm={2}>
                  <Typography variant='caption' component='div'>
                    {t('SCHEDULER.ALL_DAY')}:
                  </Typography>
                  <CheckBox
                    disabled={isDisabled()}
                    name='allDay'
                    id='allDay'
                    checked={eventSelected?.allDay || false}
                    handleChange={(e) => handleChange(e)}
                    style={{width: '50%', display: 'flex', marginLeft: '20px'}}
                  />
                </Grid> */}
                <Grid item xs={6} mt={2}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.DATE_FROM')}*
                  </Typography> */}
                  <TextField
                    disabled={isDisabled()}
                    fullWidth
                    id='eventDateFrom'
                    name='eventDateFrom'
                    size='small'
                    type='date'
                    value={
                      eventSelected?.eventDateFrom
                        ? moment(eventSelected?.eventDateFrom).format(
                                'YYYY-MM-DD'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    /*
                      inputProps={{
                      max: eventSelected?.eventDateTo
                        ? moment(eventSelected?.eventDateTo).subtract(1, 'days').format('YYYY-MM-DDTHH:mm')
                        : undefined,
                    }}
                    */
                  />
                </Grid>
                <Grid item xs={6} mt={2}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.DATE_TO')}*
                  </Typography> */}
                  <TextField
                    disabled={isDisabled()}
                    fullWidth
                    id='eventDateTo'
                    name='eventDateTo'
                    size='small'
                    type='date'
                    value={
                      eventSelected?.eventDateTo
                        ? moment(eventSelected?.eventDateTo).format(
                                'YYYY-MM-DD'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    // Limitación de eventDateTo comentada
                    //inputProps={{
                    //  min: eventSelected?.eventDateFrom
                    //    ? moment(eventSelected?.eventDateFrom).format('YYYY-MM-DDTHH:mm')
                    //    : undefined,
                    //}}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.DATE_FROM')}*
                  </Typography> */}
                  <TextField
                    disabled={isDisabled()}
                    fullWidth
                    id='eventTimeFrom'
                    name='eventTimeFrom'
                    size='small'
                    type='time'
                    value={
                      eventSelected?.eventDateFrom
                        ? moment(eventSelected?.eventDateFrom).format(
                                'HH:mm'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.DATE_TO')}*
                  </Typography> */}
                  <TextField
                    disabled={isDisabled()}
                    fullWidth
                    id='eventDateTo'
                    name='eventTimeTo'
                    size='small'
                    type='time'
                    value={
                      eventSelected?.eventDateTo
                        ? moment(eventSelected?.eventDateTo).format(
                                'HH:mm'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.TITLE')}*
                  </Typography> */}
                  { !categoryListBlocked.includes(eventSelected?.idCategory) && (
                    <TextField
                      placeholder={t('LABEL.TITLE')}
                      InputProps={{
                        sx: {
                          '& ::placeholder': {
                            color: 'black', // Estilo del placeholder
                          },
                        },
                      }}
                      disabled={isDisabled()}
                      required
                      name='eventTitle'
                      value={eventSelected?.eventTitle || ''}
                      size='small'
                      onChange={(e) => handleChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                    />
                  )}
                  { categoryListBlocked.includes(eventSelected?.idCategory) && (
                    <div 
                      dangerouslySetInnerHTML={{__html: transformText(eventSelected?.eventTitle)}}
                      style={{
                        border: '1px solid #D3D3D3',
                        borderRadius: '3px',
                        padding: '0.5em'
                      }}
                    ></div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* <Typography variant='caption' component='div'>
                    {t('LABEL.INFO')}:
                  </Typography> */}
                  { !categoryListBlocked.includes(eventSelected?.idCategory) && (
                    <TextareaAutosize
                      placeholder={t('LABEL.INFO')} 
                      disabled={isDisabled()}
                      required
                      minRows={3}
                      style={{ 
                        width: '100%',
                        border: '1px solid lightgray', 
                        borderRadius: '5px', 
                        margin: '5px 0px', 
                        fontSize: '1em', 
                        padding: '10px'
                      }}
                      sx={{
                        '& ::placeholder': {
                          color: 'rgba(127,127,127,0.2) !important', // Estilo del placeholder
                        },
                      }}
                      id='eventInfo'
                      name='eventInfo'
                      value={eventSelected?.eventInfo ? eventSelected.eventInfo.replace(/<br\s*\/?>/g, '\n') : ''} // Reemplaza los <br /> por \n
                      onChange={(e) => handleChange(e)}
                    />
                  )}
                  { categoryListBlocked.includes(eventSelected?.idCategory) && (
                    <div 
                      dangerouslySetInnerHTML={{__html: transformText(eventSelected?.eventInfo)}}
                      style={{
                        border: '1px solid #D3D3D3',
                        borderRadius: '3px',
                        padding: '0.5em',
                        minHeight: '2em'
                      }}
                    ></div>
                  )}
                </Grid>
              </>
            )}
            {page === 2 && (
              <>
                <Grid item container xs={12} >
                  {/* <Grid item xs={5}>
                    <Typography mr={1} variant='caption'>{t('SCHEDULER.TYPE')}*:</Typography>
                    <RadioGroup
                      id='eventPrivate'
                      name='eventPrivate'
                      value={eventSelected?.eventPrivate || false}
                      onChange={(e) => handleChange(e)}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.PUBLIC')}
                        className={classes.radio}
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.PRIVATE')}
                        className={classes.radio}
                      />
                    </RadioGroup>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Typography mr={1} variant='caption'>{t('LABEL.STATE')}:</Typography>
                    <RadioGroup
                      id='eventConfirmed'
                      name='eventConfirmed'
                      value={eventSelected?.eventConfirmed !== undefined ? eventSelected.eventConfirmed : true}
                      onChange={(e) => handleChange(e)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.CONFIRMED')}
                        className={classes.radio}
                        sx={{
                          width: '48%'
                        }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.BLOCKED')}
                        className={classes.radio}
                        sx={{
                          width: '48%'
                        }}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6} pr={2} mt={1}>
                    <Typography variant='caption'>{t('SCHEDULER.BLOCK_BE')}:</Typography>
                    <TextField
                      required
                      name='eventTimePreBlocked'
                      value={eventSelected?.eventTimePreBlocked || 0}
                      size='small'
                      onChange={(e) => handleChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={6} pr={2} mt={1}>
                    <Typography variant='caption'>{t('SCHEDULER.BLOCK_AF')}:</Typography>
                    <TextField
                      required
                      name='eventTimePostBlocked'
                      value={eventSelected?.eventTimePostBlocked || 0}
                      size='small'
                      onChange={(e) => handleChange(e)}
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Typography mr={1} variant='caption'>{t('SCHEDULER.NOTICE')}:</Typography>
                    <RadioGroup
                      id='eventTimeNotice'
                      name='eventTimeNotice'
                      value={eventSelected?.eventTimeNotice || 0}
                      onChange={(e) => handleChange(e)}
                      row
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio size='small' />}
                        label={'No'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                      <FormControlLabel
                        value={15}
                        control={<Radio size='small' />}
                        label={'15m'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                      <FormControlLabel
                        value={30}
                        control={<Radio size='small' />}
                        label={'30m'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                      <FormControlLabel
                        value={60}
                        control={<Radio size='small' />}
                        label={'60m'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                      <FormControlLabel
                        value={90}
                        control={<Radio size='small' />}
                        label={'90m'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                      <FormControlLabel
                        value={120}
                        control={<Radio size='small' />}
                        label={'120m'}
                        className={classes.radio}
                        sx={{width: '30%'}}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                {/* <Grid item container xs={12} sm={5.8} sx={{borderLeft: '1px dotted lightgray', paddingLeft:'10px !important'}} >
                  <Grid item xs={12}>
                    <Typography mr={1} variant='caption'>{t('SCHEDULER.REPEAT')}*:</Typography>
                    <RadioGroup
                      id='recurrentFrequencyUnit' 
                      name='recurrentFrequencyUnit'
                      value={eventSelected?.recurrentFrequencyUnit || ''}
                      onChange={(e) => handleChange(e)} 
                      row
                    >
                      <FormControlLabel
                        value={''}
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.NO_REPEAT')}
                        className={classes.radio}
                        sx={{width: '55%'}}
                      />
                      <FormControlLabel
                        value='year'
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.YEAR')}
                        className={classes.radio}
                      />
                      <FormControlLabel
                        value='month'
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.MONTH')}
                        className={classes.radio}
                        sx={{width: '55%'}}
                      />
                      <FormControlLabel
                        value='week'
                        control={<Radio size='small' />}
                        label={t('SCHEDULER.WEEK')}
                        className={classes.radio}
                      />
                    </RadioGroup>
                  </Grid>
                  {(eventSelected?.isRecurrent || eventSelected?.idRecurrentEvent) && (
                    <Grid item xs={12}>
                      <Typography variant='caption'>{t('SCHEDULER.RECURRENT_TEXT')}*:</Typography>
                      <RadioGroup
                        id='recurrentTypeEnd' 
                        name='recurrentTypeEnd'
                        value={(eventSelected?.recurrentTypeEnd) || (eventSelected?.recurrentDateLimit !== null ? 'recurrentDateLimit' : 'recurrentRepeats')}
                        onChange={(e) => handleChange(e)} 
                        style={{marginTop: '8px'}}
                      >
                        <FormControlLabel
                          value={'recurrentDateLimit'}
                          control={<Radio size='small' />}
                          label={
                            <TextField
                              id='recurrentDateLimit'
                              name='recurrentDateLimit'
                              size='small'
                              type='datetime-local'
                              value={
                                eventSelected?.recurrentDateLimit
                                  ? moment(eventSelected?.recurrentDateLimit).format(
                                          'YYYY-MM-DDT00:00:00'
                                        )
                                  : ''
                              }
                              onChange={(e) => handleChange(e)}
                              disabled={eventSelected.recurrentTypeEnd === 'recurrentRepeats'}
                            />
                          }
                          style={{marginRight: '0px !important', width: '100% !important'}}
                        />
                        <FormControlLabel
                          value={'recurrentRepeats'}
                          control={<Radio size='small' />}
                          label={
                            <TextField
                              name='recurrentRepeats'
                              value={eventSelected?.recurrentRepeats || 0}
                              size='small'
                              onChange={(e) => handleChange(e)}
                              inputProps={{ maxLength: 3 }}
                              type='number'
                              disabled={eventSelected.recurrentTypeEnd === 'recurrentDateLimit'}
                            />
                          }
                          style={{marginRight: '0px !important', width: '100% !important', marginTop: '5px'}}
                        />
                      </RadioGroup>
                    </Grid>
                  )}
                </Grid> */}
              </>
            )}
            {page === 3 && (
              <>
                <Grid item xs={12}>
                  <Typography sx={{fontWeight: 'bold'}} mr={2}>{t('SCHEDULER.USER')}:</Typography>
                  <Select
                    multiple
                    id='idUsers'
                    fullWidth
                    size='small'
                    name='idUsers'
                    value={eventSelected.idUsers || idUsersList}
                    onChange={(e) => handleChange(e)}
                  >
                    {userList?.map((user, i) => (
                      <MenuItem
                        key={user.idUser}
                        value={user.idUser}
                        selected={idUsersList.includes(user.idUser)}
                        style={{ 
                          backgroundColor: idUsersList.includes(user.idUser) ? '#0085B4' : 'inherit',
                          color: idUsersList.includes(user.idUser) ? 'white' : 'inherit' 
                        }}
                      >
                        {user.name} {user.surname}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography sx={{fontWeight: 'bold'}} variant={'caption'} mr={2}>{t('SCHEDULER.SEARCH_FREE')}:</Typography>
                  <CheckBox
                    disabled={isDisabled()}
                    name='freeSlotsSearch'
                    id='freeSlotsSearch'
                    checked={freeSlotsSearch || false}
                    handleChange={(e) => setFreeSlotsSearch(
                      (prevEvent) => (!prevEvent)
                    )}
                    style={{width: '25%', display: 'flex', marginLeft: 'auto', marginRight: 'auto'}}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{fontWeight: 'bold'}} variant={'caption'} mr={2}>Desde:</Typography>
                  <TextField
                    disabled={!freeSlotsSearch}
                    fullWidth
                    id='freeSlotDateFrom'
                    name='freeSlotDateFrom'
                    size='small'
                    type='datetime-local'
                    value={
                      eventSelected?.freeSlotDateFrom
                        ? moment(eventSelected?.freeSlotDateFrom).format(
                                'YYYY-MM-DDTHH:mm'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                      max: eventSelected?.freeSlotDateTo
                        ? moment(eventSelected?.freeSlotDateTo).subtract(1, 'days').format('YYYY-MM-DDTHH:mm')
                        : undefined,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{fontWeight: 'bold'}} variant={'caption'} mr={2}>Hasta:</Typography>
                  <TextField
                    disabled={!freeSlotsSearch}
                    fullWidth
                    id='freeSlotDateTo'
                    name='freeSlotDateTo'
                    size='small'
                    type='datetime-local'
                    value={
                      eventSelected?.freeSlotDateTo
                        ? moment(eventSelected?.freeSlotDateTo).format(
                                'YYYY-MM-DDTHH:mm'
                              )
                        : ''
                    }
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                      min: eventSelected?.freeSlotDateFrom
                        ? moment(eventSelected?.freeSlotDateFrom).format('YYYY-MM-DDTHH:mm')
                        : undefined,
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        }
        actions={
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} width='100%' sx={{padding: '0px !important'}}>
            <Grid item xs={!isConsultant ? 6.5 : 2.5} sm={4.5} display={'flex'} justifyContent={'start'} p={0}>
              {!isDisabled() && !categoryListBlocked.includes(eventSelected?.idCategory) && (
                <Button
                  sx={{
                    left: '0px',
                    width: {
                      xs: '40%',
                      sm: 'auto'
                    },
                    whiteSpace: 'nowrap',
                    marginLeft: '-8px'
                  }}
                  variant='outlined'
                  onClick={() => {
                    if (page === 1) {
                      setPage(2)
                    } else {
                      setPage(1)
                    }
                  }}
                >
                  {page === 1 ? t('SCHEDULER.SEE_MORE') : t('SCHEDULER.BACK')}
                </Button>
              )}
              {!isConsultant && !eventSelected?.idSchedulerEvent && !categoryListBlocked.includes(eventSelected?.idCategory) && (
                <Button
                  sx={{
                    left: '0px', 
                    width: {
                      xs: '50%',
                      sm: 'auto'
                    }, 
                    marginLeft: '3px',
                    display: page === 3 ? 'none': 'block'
                  }}
                  variant='outlined'
                  onClick={() => {setPage(3)}}
                >
                {t('SCHEDULER.USERS')}
                </Button>
              )}
            </Grid>
            <Grid item xs={!isConsultant ? 5.5 : 9.5} sm={7.5} mt={0} display={'flex'} justifyContent={'end'}>
              {!isDisabled() && !categoryListBlocked.includes(eventSelected?.idCategory) && freeSlotsSearch && (
                <Button
                  sx={{marginRight: '0px'}}
                  variant='contained'
                  color='info'
                  onClick={(e) => {
                    // console.log(eventSelected.idUsers, eventSelected.freeSlotDateFrom, eventSelected.freeSlotDateTo);
                    const dataQuery = { 
                      IdUsers: eventSelected.idUsers,
                      StartDate: eventSelected.freeSlotDateFrom, 
                      EndDate: eventSelected.freeSlotDateTo 
                    }  
                    fetchFeeSlots(dataQuery, setFreeSlots, setOpenLoading, setLoading, setOpenFreeSlots)
                  }}
                  disabled={idUsersList.length <= 1 || !eventSelected.freeSlotDateFrom || !eventSelected.freeSlotDateTo }
                >
                  {t('BUTTON.AUTO')}
                </Button>
              )}
              {!isDisabled() && !categoryListBlocked.includes(eventSelected?.idCategory) && (
                <Button
                  sx={{marginRight: '5px',
                    width: {
                      xs: '50%',
                      sm: 'auto'
                    },
                  }}
                  variant='contained'
                  color='success'
                  onClick={() => handleSaveButton()}
                  disabled={!eventSelected?.idCategory || !eventSelected?.eventDateFrom || !eventSelected?.eventDateTo || !eventSelected?.eventTitle}
                >
                  {t('BUTTON.SAVE')}
                </Button>
              )}
              {(!isDisabled() &&eventSelected?.idSchedulerEvent || eventSelected?.idSchedulerEvent === 0) && !categoryListBlocked.includes(eventSelected?.idCategory) && (
                <>
                  <Button
                    sx={{marginRight: '5px'}}
                    variant='contained'
                    color='warning'
                    onClick={() => handleCloneButton()}
                    disabled={!eventSelected?.idCategory || !eventSelected?.eventDateFrom || !eventSelected?.eventDateTo || !eventSelected?.eventTitle}
                  >
                    {t('BUTTON.CLONE')}
                  </Button>
                  <Button
                    sx={{marginRight: '5px'}}
                    variant='contained'
                    color='error'
                    onClick={() => handleDeleteButton()}
                  >
                    {t('BUTTON.DELETE')}
                  </Button>
                </>
              )}
              <Button
              sx={{marginRight: '-8px'}}
                variant='contained'
                onClick={() => {
                  // sessionStorage.removeItem('idEventSelected')
                  setOpenEventDetails(false)
                }}
              >
                {t('BUTTON.CLOSE')}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <Dialog
        open={openEventSaveRecurrent}
        close={() => {
          setOpenEventSaveRecurrent(false)
        }}
        subtitle={t('SCHEDULER.RECURRENT')}
        width={250}
        content={
          <Grid container spacing={0.5}>
            <Grid item xs={12}>
              <Typography>
                {t('SCHEDULER.WHICH_EVENT')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                id='recurrentTypeUpdate'
                name='recurrentTypeUpdate'
                value={eventSelected?.recurrentTypeUpdate || '1'}
                onChange={(e) => handleChange(e)}
              >
                <FormControlLabel
                  value='1'
                  control={<Radio size='small' />}
                  label={t('SCHEDULER.ALL_EVENTS')}
                  className={classes.radio}
                />
                <FormControlLabel
                  value='2'
                  control={<Radio size='small' />}
                  label={t('SCHEDULER.JUST_EVENTS')}
                  className={classes.radio}
                />
                <FormControlLabel
                  value='3'
                  control={<Radio size='small' />}
                  label={t('SCHEDULER.THIS_AEVENTS')}
                  className={classes.radio}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        }
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                sx={{marginRight: '5px'}}
                variant='contained'
                color='success'
                onClick={() => {
                  if (!isDelete) {
                    updateEvent(eventSelected, setOpenLoading, setOpenEventDetails, setEventSelected, eventSelected?.recurrentTypeUpdate || '')
                    setOpenEventSaveRecurrent(false)
                  } else {
                    deleteEvent(eventSelected, setOpenLoading, setEventSelected, setOpenEventDetails, eventSelected?.recurrentTypeUpdate ||'')
                    setOpenEventSaveRecurrent(false)
                  }
                }
                }
              >
                {t('BUTTON.OK')}
              </Button>
              <Button
                variant='contained'
                onClick={() => setOpenEventSaveRecurrent(false)}
              >
                {t('BUTTON.CLOSE')}
              </Button>
            </Box>
          </Box>
        }
      />
      
      <Dialog
        open={openFreeSlots}
        close={() => {
          setOpenFreeSlots(false)
        }}
        subtitle={
          <>
            <Typography align="center" component="div" sx={{ color: 'black', mt: -2 }}>
              {`${t('SCHEDULER.RESULTS_FOUND')}: ${freeSlots.length}`}
            </Typography>
            <Typography align="center" component="div" sx={{ color: 'black', mt: -0.5 }}>
              {`${t('TEXT.PAGE')} ${currentPage + 1} ${t('TEXT.OF')} ${Math.ceil(freeSlots.length / eventsPerPage)}`}
            </Typography>
            <Typography variant="subtitle2" align="center" component="div" sx={{ mb: -2 }}>
              {t('EVALUATOR.SELECT_OPTION')}
            </Typography>
          </>
        }        
        width={290}
        sx={{zIndex:'999999', height: 'auto'}}
        content={
          <Box sx={{ height: '300px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {freeSlots.length > 0 ? (
                  <RadioGroup
                    id='freeSlotsRadio'
                    name='freeSlotsRadio'
                    value={eventSelected?.freeSlotsRadio || ''}
                    onChange={(e) => handleChange(e)}
                    sx={{width:'100%', display:'flex', justifyContent: 'center'}}
                  >
                    {currentEvents.map((freeSlot, i) => (
                      <>
                        <FormControlLabel
                          mt={1}
                          mb={1}
                          key={i}
                          value={freeSlot.startDate}
                          control={<Radio size='small' />}
                          label={
                            <div style={{marginLeft: '1em', width: '100%', display:'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                              <Typography mt={1} mb={1}>{moment(freeSlot.startDate).format('DD-MM-YYYY HH:mm')}</Typography>
                              <Typography mb={1}>{moment(freeSlot.endDate).format('DD-MM-YYYY HH:mm')}</Typography>
                            </div>
                          }
                        />
                        <hr/>
                      </>
                    ))}
                  </RadioGroup>
                ) : (
                  <Typography align="center" sx={{ mt: 2 }}>
                    {t('SCHEDULER.NO_RESULTS_FOUND')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        }
        
        actions={
          <Box display='flex' justifyContent="center" alignItems='center' flexWrap="wrap" gap={2}>
            <Box>
              <Button
                variant='contained'
                disabled={!previousPageAvailable}
                onClick={handlePreviousPage}
                sx={{ mr: 1 }}
              >
                {t('BUTTON.PREVIOUS')}
              </Button>
              <Button
                variant='contained'
                disabled={!nextPageAvailable}
                onClick={handleNextPage}
              >
                {t('BUTTON.NEXT')}
              </Button>
            </Box>
            <Box>
              <Button
                sx={{marginRight: '5px'}}
                variant='contained'
                color='success'
                onClick={(e) => handleFreeSlotsRadio(e)}
              >
                {t('BUTTON.OK')}
              </Button>
              <Button
                variant='contained'
                onClick={() => setOpenFreeSlots(false)}
              >
                {t('BUTTON.CLOSE')}
              </Button>
            </Box>
          </Box>
        }        
      />
    </>
  )
}

export default DialogEvent;
